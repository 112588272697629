import React, { useEffect, useState } from 'react';
import { db } from '../../login/firebase'; // Import de la configuration Firebase
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import './adminlo.css';

function AdminLo() {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Logiciel-client'));
                const clientsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setClients(clientsData);
            } catch (error) {
                console.error("Erreur lors de la récupération des clients :", error);
            }
        };

        fetchClients();
    }, []);

    // Fonction pour changer le statut de l'envoi
    const updateSendStatus = async (clientId, newStatus) => {
        try {
            const clientRef = doc(db, 'Logiciel-client', clientId);
            await updateDoc(clientRef, { status: newStatus });
            setClients(clients.map((client) =>
                client.id === clientId ? { ...client, status: newStatus } : client
            ));
            console.log(`Statut mis à jour pour le client ${clientId}: ${newStatus}`);
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error);
        }
    };

    return (
        <div className="adminLo">
            <h2>Liste des Clients</h2>
            {clients.length > 0 ? (
                <ul className="client-list">
                    {clients.map((client) => (
                        <li key={client.id} className="client-item">
                            <h3>Nom : {client.name}</h3>
                            <p>Email : {client.email}</p>
                            <p>Produit : {client.plan}</p>

                            {/* Ajout du statut de l'envoi */}
                            <p>Statut d'envoi : {client.status || 'Pas encore envoyé'}</p>
                            
                            {/* Menu pour changer le statut */}
                            <select
                                value={client.status || 'Pas encore envoyé'}
                                onChange={(e) => updateSendStatus(client.id, e.target.value)}
                            >
                                <option value="Pas encore envoyé">Pas encore envoyé</option>
                                <option value="Envoyé">Envoyé</option>
                            </select>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Aucun client trouvé.</p>
            )}
        </div>
    );
}

export default AdminLo;
