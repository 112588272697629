import React from 'react';
import './logiciel.css';
import { useNavigate } from 'react-router-dom';

function Logiciel() {
  const navigate = useNavigate();

  const handleSubscription = (offerId) => {
    navigate(`/projectformlogicial`,{ state: { offerId: offerId } });     
  };

  return (
    <div className="logiciel-container">
      <section className="intro-section">
        <h1>Découvrez notre logiciel de conformité avancé</h1>
        <p>
          Un outil puissant et intuitif conçu pour les cabinets et les petites équipes, vous permettant d'automatiser les contrôles comptables, d'assurer la conformité, et de détecter les anomalies dans vos données. 
          Simplifiez votre gestion et assurez-vous de répondre aux normes fiscales en toute sérénité.
        </p>
      </section>

      <section className="features-section">
        <div className="feature-card">
          <span className="emoji">👌</span>
          <h3>Intuitif</h3>
          <p>Une prise en main ultra simple, sans paramétrage.</p>
        </div>
        <div className="feature-card">
          <span className="emoji">🔑</span>
          <h3>Accessibilité directe</h3>
          <p>Un logiciel SaaS accessible en deux clics.</p>
        </div>
        <div className="feature-card">
          <span className="emoji">📜</span>
          <h3>Conforme</h3>
          <p>100% conforme aux normes fiscales.</p>
        </div>
        <div className="feature-card">
          <span className="emoji">📈</span>
          <h3>Évolutivité</h3>
          <p>Un logiciel proposant des évolutions permanentes.</p>
        </div>
        <div className="feature-card">
          <span className="emoji">💻</span>
          <h3>Technologie</h3>
          <p>Une capacité technologique hors du commun pour traiter toutes les situations.</p>
        </div>
        <div className="feature-card">
          <span className="emoji">🔒</span>
          <h3>Sécurité</h3>
          <p>Nous utilisons les plus hauts standards de sécurité informatique.</p>
          
        </div>
      </section>
      <h1>Forfaits et tarifs</h1>
      <section className="offers-section">
        <div className="offer-card" onClick={() => handleSubscription('starter-compliance')}>
          <h3>Starter Compliance</h3>
          <p>10 € / mois</p>
          <p>Idéal pour analyser la conformité de vos FEC.</p>
          <button>Acheter maintenant</button>
        </div>
        <div className="offer-card" onClick={() => handleSubscription('starter-scoring')}>
          <h3>Starter Scoring</h3>
          <p>40 € / mois</p>
          <p>Idéal pour détecter les anomalies comptables.</p>
          <button>Acheter maintenant</button>
        </div>
        <div className="offer-card" onClick={() => handleSubscription('starter-full')}>
          <h3>Starter Full</h3>
          <p>50 € / mois</p>
          <p>Pour une automatisation complète des contrôles.</p>
          <button>Acheter maintenant</button>
        </div>
        <div className="offer-card" onClick={() => handleSubscription('enterprise')}>
          <h3>Enterprise</h3>
          <p>Personnalisé</p>
          <p>Pour les grandes entreprises avec de gros volumes.</p>
          <button>Acheter maintenant</button>
        </div>
      </section>
    </div>
  );
}

export default Logiciel;
