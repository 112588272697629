import './App.css';
import Login from './componates/login/Login';
import Navbar from './componates/navbar/Navbar';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Shop from './componates/shop/Shop';
import Service from './Service';
import Work from './componates/work/Work';
import ProjectForm from './componates/offers/ProjectForm';
import Merci from './componates/merci/Merci';
import AdminNav from './componates/admin/adminnavbar/AdminNav';
import { useEffect, useState } from 'react';
import { auth, db } from './componates/login/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Logiciel from './componates/productDetaille/Logiciel/Logiciel';
import FormLo from './componates/productDetaille/formdata/FormLo';
import ServiceForm from './componates/offers/ServiceForm';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Observer l'état de l'utilisateur
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.role === 'admin');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Charger Tawk.to sur toutes les pages
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/673bc4fc4304e3196ae4b289/1id0nivgs';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    return () => {
      // Nettoyage du script pour éviter les conflits
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/service" element={<Service />} />
          <Route path="/portfolio" element={<Work />} />
          <Route path="/projectform" element={<ProjectForm />} />
          <Route path="/serviceform" element={<ServiceForm />} />
          <Route path="/merci" element={<Merci />} />
          <Route path="/login" element={<Login />} />
          <Route path="/aboutlogiciel" element={<Logiciel />} />
          <Route path="/projectformlogicial" element={<FormLo />} />
          <Route
            path="/admin"
            element={isAdmin ? <AdminNav /> : <Navigate to="/login" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
