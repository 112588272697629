import React, { useState, useEffect } from "react";
import { db } from "../../login/firebase"; // Assurez-vous que votre chemin est correct
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import './adminservice.css'; // Ajoutez un fichier CSS si nécessaire

function AdminService() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fonction pour récupérer les services depuis Firestore
  const fetchServices = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "service"));
      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des services :", error);
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour l'état d'un service
  const updateServiceStatus = async (serviceId, newStatus) => {
    try {
      const serviceRef = doc(db, "service", serviceId);
      await updateDoc(serviceRef, { status: newStatus });
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId ? { ...service, status: newStatus } : service
        )
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  // Chargement initial des services
  useEffect(() => {
    fetchServices();
  }, []);

  if (loading) {
    return <p>Chargement des services...</p>;
  }

  return (
    <div className="admin-service-container">
      <h2>Gestion des Services</h2>
      {services.length === 0 ? (
        <p>Aucun service trouvé.</p>
      ) : (
        services.map((service) => (
          <div className="service-card" key={service.id}>
            <h3>{service.name}</h3>
            <p><strong>Description :</strong> {service.description}</p>
            <p><strong>Numéro de téléphone :</strong> {service.phoneNumber}</p>
            <p><strong>Devise :</strong> {service.currency}</p>
            {service.imageUrl && (
              <img src={service.imageUrl} alt="Service" className="service-image" />
            )}
            <p><strong>Statut actuel :</strong> {service.status || "Non défini"}</p>
            <div className="status-buttons">
              <button
                onClick={() => updateServiceStatus(service.id, "Prise de contact")}
                className="btn-status"
              >
                Prise de contact
              </button>
              <button
                onClick={() => updateServiceStatus(service.id, "Livraison en cours")}
                className="btn-status"
              >
                Livraison en cours
              </button>
              <button
                onClick={() => updateServiceStatus(service.id, "Livré")}
                className="btn-status"
              >
                Livré
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default AdminService;
