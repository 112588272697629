
import React from 'react';
import './partenaires.css'
import adex from '../assets/images__1_-removebg-preview.png'
import kanatic from '../assets/LOGO-KANTIK_Bleu.webp'

function Partenaires() {
    return (
        <div className="partenaires-container">
            <h3>Nos Partenaires Technologiques</h3>
            <div className="logos">
            <img src={adex} alt="adex" />
            <img src={kanatic} alt="kanatic" />
            </div>
        </div>
    );
}

export default Partenaires;
