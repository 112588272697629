import React, { useState } from 'react';
import { db } from '../../login/firebase'; // Assurez-vous que votre configuration Firebase est correcte
import { collection, addDoc } from 'firebase/firestore';
import './formlo.css';

function FormLo(props) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        company: '',
        message: '',
        plan: ''
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.plan) {
            setError("Veuillez sélectionner un forfait.");
            return;
        }

        try {
            await addDoc(collection(db, 'Logiciel-client'), formData);
            alert('Informations envoyées avec succès!');
            setFormData({
                name: '',
                phone: '',
                email: '',
                company: '',
                message: '',
                plan: ''
            });
            setError('');
        } catch (error) {
            console.error("Erreur lors de l'envoi des données:", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    return (
        <div className="form-container">
            <h2>Formulaire de souscription</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Nom:</label>
                <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                />

                <label htmlFor="phone">Numéro de téléphone:</label>
                <input 
                    type="tel" 
                    id="phone" 
                    name="phone" 
                    value={formData.phone} 
                    onChange={handleChange} 
                    required 
                />

                <label htmlFor="email">Email:</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                />

                <label htmlFor="company">Entreprise (optionnel):</label>
                <input 
                    type="text" 
                    id="company" 
                    name="company" 
                    value={formData.company} 
                    onChange={handleChange} 
                />

                <label htmlFor="message">Message:</label>
                <textarea 
                    id="message" 
                    name="message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    rows="4"
                ></textarea>

                <label htmlFor="plan">Forfait:</label>
                <select 
                    id="plan" 
                    name="plan" 
                    value={formData.plan} 
                    onChange={handleChange} 
                    required
                >
                    <option value="">-- Sélectionnez un forfait --</option>
                    <option value="Starter Compliance">Starter Compliance</option>
                    <option value="Starter Scoring">Starter Scoring</option>
                    <option value="Starter Full">Starter Full</option>
                    <option value="Enterprise">Enterprise</option>
                </select>

                {error && <p className="error-message">{error}</p>}

                <button type="submit" className="submit-btn">Envoyer</button>
            </form>
        </div>
        
    );
}

export default FormLo;
