import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../login/firebase'; // Assurez-vous que votre chemin vers Firebase est correct
import './service.css';

function ProductService() {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    const services = [
        {
            id: 'service1',
            title: 'Design UI/UX sur mesure',
            description: 'Améliorez l\'expérience utilisateur de vos applications avec des designs UI/UX modernes et intuitifs. Nous nous concentrons sur l\'ergonomie, la simplicité d\'utilisation et un look élégant, tout en utilisant les dernières tendances et technologies pour vous garantir une satisfaction optimale.',
            prix1: '150,000 DA',
            prix2: '$500',
        },
        {
            id: 'service2',
            title: 'Maintenance & Support Technique',
            description: 'Assurez-vous que votre site web ou application reste performant, sécurisé et sans bugs grâce à notre service de maintenance complet. Nous offrons des mises à jour régulières, des sauvegardes automatiques et un support technique disponible 24/7.',
            prix1: '100,000 DA',
            prix2: '$350',
        },
        {
            id: 'service3',
            title: 'SEO & Marketing Digital',
            description: 'Augmentez votre présence en ligne grâce à une stratégie de référencement naturel (SEO) efficace et un marketing digital sur mesure. Nous analysons votre marché cible, optimisons vos contenus et mettons en place des campagnes pour maximiser votre visibilité.',
            prix1: '80,000 DA',
            prix2: '$300',
        },
        {
            id: 'service4',
            title: 'Automatisation des Processus',
            description: 'Simplifiez vos tâches répétitives et gagnez en efficacité avec des solutions d\'automatisation adaptées à votre entreprise. Nous mettons en place des systèmes qui vous permettent de vous concentrer sur ce qui est vraiment important.',
            prix1: '200,000 DA',
            prix2: '$700',
        },
        {
            id: 'service5',
            title: 'Conseil en Sécurité Informatique',
            description: 'Sécurisez vos données sensibles et vos infrastructures IT grâce à notre expertise en sécurité informatique. Nous offrons des audits complets, des solutions de protection et des conseils pour éviter les menaces et attaques cybernétiques.',
            prix1: '180,000 DA',
            prix2: '$600',
        },
    ];

    const handlePurchaseClick = (serviceId) => {
        if (!user) {
            navigate('/login');
        } else {
            navigate('/serviceform', { state: { serviceId: serviceId } });
        }
    };

    return (
        <div className="service-container">
            {services.map((service) => (
                <div className="service-card" key={service.id}>
                    <h2 className="service-title">{service.title}</h2>
                    <p className="service-description">{service.description}</p>
                    <div className="service-prices">
                        <p className="local-price">Prix (DA): {service.prix1}</p>
                        <p className="international-price">Prix (USD): {service.prix2}</p>
                    </div>
                    <button className="btn" onClick={() => handlePurchaseClick(service.id)}>
                        Acheter
                    </button>
                </div>
            ))}
        </div>
    );
}

export default ProductService;
