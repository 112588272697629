import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db, storage } from "../login/firebase";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import './form.css';

const ServiceForm = () => {
  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
    phoneNumber: "",
    currency: "DZD", // Devise par défaut
  });
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Récupération de serviceId depuis location.state
  const { serviceId } = location.state || {}; // Peut être undefined

  const handleChange = (e) => {
    setServiceData({
      ...serviceData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = "";

      // Téléchargement de l'image si elle est présente
      if (image) {
        const imageRef = ref(storage, `service-images/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Ajout d'un nouveau document dans la collection "service"
      await addDoc(collection(db, "service"), {
        ...serviceData,
        imageUrl,
        createdAt: new Date(),
        serviceId: serviceId || "default-service-id", // Utilise une valeur par défaut si undefined
      });

      // Redirection après la soumission
      navigate('/merci');

    } catch (error) {
      console.error("Erreur lors de l'enregistrement du service : ", error);
    }
  };

  return (
    <div>
      <h2>Créer un Service</h2>
      {/* Message d'avertissement si serviceId est absent */}
      {!serviceId && (
        <p style={{ color: "red" }}>
          ⚠️ Aucune référence de service transmise. Un ID par défaut sera utilisé.
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Nom du service"
          value={serviceData.name}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Description"
          value={serviceData.description}
          onChange={handleChange}
          required
        ></textarea>
        <input
          type="tel"
          name="phoneNumber"
          placeholder="Numéro de téléphone"
          value={serviceData.phoneNumber}
          onChange={handleChange}
          required
        />

        {/* Sélecteur pour la devise */}
        <label htmlFor="currency">Choisissez votre devise :</label>
        <select
          name="currency"
          value={serviceData.currency}
          onChange={handleChange}
          required
        >
          <option value="DZD">Dinar Algérien (DZD)</option>
          <option value="EUR">Euro (EUR)</option>
          <option value="USD">Dollar (USD)</option>
        </select>

        <input type="file" onChange={handleImageChange} />
        <button type="submit">Soumettre le service</button>
      </form>
    </div>
  );
};

export default ServiceForm;
