import React from 'react';
import Offers from './componates/offers/Offers';
import ProductService from './componates/offers/ProductService';
import './servicetotal.css'
function Service(props) {
    return (
        <div>
            <h1 className='mid'>Nos Produits</h1>
            <Offers />
            <h1 className='mid'>Nos Service</h1>
            <ProductService/>
        </div>
    );
}

export default Service;